import { ChartConfiguration, Chart as Chartjs } from "chart.js"
// @ts-ignore
import C2S from "./canvas2svg"

export function downloadAllSvgs() {
    const allCharts = Chartjs.instances
    Object.values(allCharts).forEach((chart: any) => {
        getSvgDownload(chart)
    })
}

function groupTextElements(svgString: any) {
    const svgTextUpdated = svgString.replace(
        /<g[^>]*?transform="([^"]*)"[^>]*>\s*(<text[^>]*>[\s\S]*?<\/text>)\s*<\/g>/g,
        (unused: any, transform: any, textTag: any) => {
            return textTag.replace("<text", `<text transform="${transform}"`)
        },
    )
    const svgDoc = new DOMParser().parseFromString(svgTextUpdated, "image/svg+xml")
    svgDoc
        .querySelectorAll("g")
        .forEach((group) => group.replaceWith(...Array.from(group.childNodes)))
    return new XMLSerializer().serializeToString(svgDoc)
}

function getSvgDownload(chart: Chartjs) {
    const config = chart.config as ChartConfiguration
    const { type, options, data, plugins } = config
    const defaultOptions = {
        options: {
            ...options,
            responsive: false,
        },
        type,
        data,
        plugins,
    }
    // @ts-ignore
    let svgContext = C2S(1200, 900)
    new Chartjs(svgContext, defaultOptions)

    setTimeout(() => {
        const chartSvg = svgContext.getSerializedSvg()
        let link = document.createElement("a")
        link.href = "data:image/svg+xml;utf8," + encodeURIComponent(groupTextElements(chartSvg))
        link.download = "chart.svg"
        link.click()
        window.location.reload() // TODO: find a better way to delete svgContext
    }, 50) // wait for the chart to render
}
