import { Box } from "@mantine/core"
import { useElementSize } from "@mantine/hooks"
import { Chart as ChartJS, ChartOptions } from "chart.js"
import "chart.js/auto"
import * as ChartGeo from "chartjs-chart-geo"
import ChartDataLabels from "chartjs-plugin-datalabels"
import { useEffect, useState } from "react"
import { Chart } from "react-chartjs-2"
import { utils } from "../../../dfi-utils"
import { interpolateColor, isBright } from "../colors"
import { useDataContext } from "../components/chart-data-context"
import { useChart } from "../components/context"
import { linearScale, mapOptions, wrapText } from "../utils"

ChartJS.register(
    ChartGeo.ChoroplethController,
    ChartGeo.GeoFeature,
    ChartGeo.ColorScale,
    ChartGeo.ProjectionScale,
)

const BASE_URL = process.env.REACT_APP_SHAPEFILE_BASE

const Map: React.FC<any> = ({ data, index }) => {
    const [states, setStates] = useState<any>(null)
    const { ref, width, height } = useElementSize()
    const dctx = useDataContext()
    const { form } = useChart()

    const { chart } = form.values
    const { yAxis } = chart
    const unit = utils.buildUnit(yAxis.unit, yAxis.prefix, yAxis.space)

    const selectedIndicator = yAxis.selected[index]
    const color = yAxis.info[selectedIndicator]?.color || mapOptions.defaultColor
    const startColor = interpolateColor(["#fff", color], 0.2)

    const colorScale = linearScale(
        yAxis.min ?? dctx.yAxis.min ?? 0,
        yAxis.max ?? dctx.yAxis.max ?? 0,
        0,
        1,
    )

    const options: ChartOptions<"choropleth"> = {
        scales: {
            projection: {
                axis: "x",
                projection: "mercator",
            },
            color: {
                axis: "x",
                min: yAxis.min ?? undefined,
                max: yAxis.max ?? undefined,
                interpolate: (v) => interpolateColor([startColor, color], v),
                legend: {
                    position: "top-right",
                    align: "top",
                    length: width > 480 ? 250 : 120,
                    margin: {
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 10,
                    },
                },
                ticks: {
                    callback: (v) => {
                        return utils.dataLabelFormatter(v, unit, yAxis)
                    },
                },
                missing: mapOptions.missingColor,
            },
        },
        showOutline: true,
        layout: {
            padding: 0,
        },
        plugins: {
            title: {
                display: !!chart.tiles.codes.length,
                text: wrapText(data.title, 50),
                align: "start",
            },
            legend: {
                display: false,
            },
            tooltip: {
                callbacks: {
                    label: (ctx: any) => {
                        const label = mapData.labels[ctx.dataIndex]
                        const value = ctx.dataset.data[ctx.dataIndex]?.value
                        return `${label}: ${utils.dataLabelFormatter(value, unit, yAxis)}`
                    },
                },
            },
            datalabels: {
                align: "center",
                display: chart.datalabels ? "auto" : false,
                color: (ctx) => {
                    return isBright(getColor(ctx)) ? "#555" : "#fff"
                },
                formatter: (v) =>
                    `${utils.displayFormatter(
                        v.value,
                        yAxis.hideInLabel ? "" : unit,
                        yAxis.precision,
                        yAxis.prefix,
                    )}`,
            },
        },
    }

    function getColor(context: any) {
        const value = context.dataset.data[context.dataIndex]?.value
        return isNaN(value)
            ? mapOptions.missingColor
            : interpolateColor([startColor, color], colorScale(value))
    }

    useEffect(() => {
        fetch(`${BASE_URL}/states.json`)
            .then((response) => response.json())
            .then((res: any) => {
                const states = (ChartGeo.topojson.feature(res, res.objects.states) as any).features
                setStates(states)
            })
    }, [])

    const mapData = {
        labels: states?.map((d: any) => d.properties.name),
        datasets: [
            {
                outline: states,
                borderColor: mapOptions.borderColor,
                borderWidth: 0.3,
                data: states?.map((state: any) => ({
                    feature: state,
                    value: data.values[state.properties.id],
                })),
                backgroundColor: getColor,
                hoverBackgroundColor: getColor,
            },
        ],
    }

    return (
        <Box
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                width: "100%",
            }}
            ref={ref}
        >
            {mapData.labels?.length ? (
                <Chart
                    key={width}
                    height={height < width ? height : width} // height defaults to 0.5 * width. Making it a square to occupy maximum available space, works only for india
                    width={width}
                    type="choropleth"
                    data={mapData}
                    options={options}
                    plugins={[ChartDataLabels]}
                />
            ) : null}
        </Box>
    )
}

export default Map
