import { Button, MantineColor, MantineSize, useMantineTheme } from "@mantine/core"
import React from "react"

export interface ButtonControlProps {
    data: { value: string; label: React.ReactNode; color?: MantineColor; disabled?: boolean }[]
    value?: string | null
    onChange?: (value: string) => void
    size?: MantineSize
    color?: MantineColor
}

const ButtonControl: React.FC<ButtonControlProps> = ({ color = "blue", ...props }) => {
    const theme = useMantineTheme()
    return (
        <Button.Group buttonBorderWidth={0.3}>
            {props.data.map(({ value, label, color: bcolor, disabled }) => (
                <Button
                    key={value}
                    compact
                    disabled={disabled}
                    color={props.value === value ? bcolor ?? color : "gray"}
                    size={props.size}
                    variant="light"
                    sx={{ border: `1px solid ${theme.colors.gray[3]}` }}
                    onClick={() => props.onChange?.(value)}
                >
                    {label}
                </Button>
            ))}
        </Button.Group>
    )
}

export default ButtonControl
