import { BoxProps, Button, Flex, RangeSlider, Slider, useMantineTheme } from "@mantine/core"
import { useEffect, useMemo, useState } from "react"

import { IconPlayerPauseFilled, IconPlayerPlayFilled } from "@tabler/icons-react"
import _ from "lodash"
import { useDataContext } from "../components/chart-data-context"
import { useChart } from "../components/context"

interface TimelineProps {
    range?: boolean
}

const Timeline: React.FC<BoxProps & TimelineProps> = ({ range = false, ...others }) => {
    const theme = useMantineTheme()
    const { form } = useChart()
    const dctx = useDataContext()
    const chart = form.values.chart
    const [playing, setPlaying] = useState<boolean>(false)

    const min = dctx.time.min ?? 0
    const max = dctx.time.max ?? 0
    const unique = useMemo(
        () => _.map(Array.from(dctx.time.value_labels.keys()), Number),
        [dctx.time.value_labels],
    )

    const start = Math.max(range ? chart.time.min_time ?? min : min, min)
    const end = Math.min(chart.time.max_time ?? max, max)

    const stepDuration = max > min ? 5000 / (max - min) : 0

    useEffect(() => {
        let animationTimeout: any
        if (playing) {
            if (end < max) {
                animationTimeout = setTimeout(() => {
                    const nextIndex = _.sortedLastIndex(unique, end)
                    form.setFieldValue("chart.time.max_time", unique[nextIndex])
                }, stepDuration)
            } else {
                setPlaying(false)
            }
        }

        return () => {
            if (animationTimeout) {
                clearTimeout(animationTimeout)
            }
        }
    }, [playing, end])

    if (!max) {
        return null
    }

    const commonProps = {
        style: { flex: 1 },
        size: "xs",
        min,
        max,
        step: 1,
    }

    return (
        <Flex gap="xs" align="center" {...others}>
            <Button
                compact
                className="plausible-event-name=Play+Timeline+Animation"
                variant="light"
                color={playing ? "blue" : "gray"}
                size="sm"
                onClick={() => {
                    if (playing) {
                        setPlaying(false)
                    } else {
                        if (end >= max) {
                            // reset to start
                            form.setFieldValue("chart.time.max_time", start)
                        }
                        setPlaying(true)
                    }
                }}
                leftIcon={
                    playing ? (
                        <IconPlayerPauseFilled size="1em" />
                    ) : (
                        <IconPlayerPlayFilled size="1em" />
                    )
                }
            >
                {range ? `${start} - ${end}` : end}
            </Button>

            {range ? (
                <RangeSlider
                    {...commonProps}
                    value={[start, end]}
                    onChange={(value: [number, number]) => {
                        form.setFieldValue("chart.time.min_time", value[0])
                        form.setFieldValue("chart.time.max_time", value[1])
                    }}
                />
            ) : (
                <Slider
                    {...commonProps}
                    value={end}
                    onChange={(value: number) => {
                        form.setFieldValue("chart.time.max_time", value)
                    }}
                />
            )}
        </Flex>
    )
}

export default Timeline
