import { Box, Button, Group, Overlay, Stack, Tabs, Text, useMantineTheme } from "@mantine/core"
import { IconEdit, IconX } from "@tabler/icons-react"
import React, { useMemo } from "react"
import { ButtonControl, SearchSelect } from "../../../dfi-utils"
import { useDataContext } from "./chart-data-context"
import { useChart } from "./context"

export const SelectControlTypes = ["dimensions", "tiles", "yAxis"] as const

// Derive the type from the array
export type SelectControlType = (typeof SelectControlTypes)[number]

export interface DFISelectControlData {
    key: string
    title: string
    formField: string
    items?: { value: string; label: string }[]
    singleSelect?: boolean
    onChange?: () => void
}

export const DFISelectControlsInline: React.FC<{ includeSidebarControls?: boolean }> = ({
    includeSidebarControls,
}) => {
    const { form } = useChart()
    const { chart } = form.values
    const dctx = useDataContext()

    const [selectControl, setSelectControl] = React.useState<SelectControlType | null>(null)

    // Only show controls that have more than one value and are not locked
    const visibleControls = SelectControlTypes.filter((key) => {
        const numValues = key === "yAxis" ? chart[key].codes.length : dctx[key].nunique
        return (
            numValues > 1 &&
            !chart[key].locked &&
            (chart[key].selectDisplay !== "sidebar" || includeSidebarControls)
        )
    })

    return (
        <>
            <Group>
                {visibleControls.map((control) =>
                    chart[control].selectDisplay == "tabs" ? (
                        <DFISelectControl
                            control={control}
                            variant="tabs"
                            onChange={() => setSelectControl(null)}
                        />
                    ) : (
                        <Button
                            compact
                            className={`plausible-event-name=Select+${control}`}
                            variant="line"
                            key={control}
                            onClick={() => setSelectControl(control)}
                            rightIcon={<IconEdit size="1em" />}
                        >
                            {`Select ${dctx.getDisplayLabel(control)}`}
                        </Button>
                    ),
                )}
            </Group>
            {selectControl && (
                <DFISelectOverlay control={selectControl} onClose={() => setSelectControl(null)} />
            )}
        </>
    )
}

export const DFISelectControl: React.FC<{
    control: SelectControlType
    variant: "search" | "tabs"
    onChange?: () => void
}> = ({ control, variant, onChange }) => {
    const { form } = useChart()
    const dctx = useDataContext()

    const chart = form.values.chart

    const items = useMemo(() => {
        const values =
            control === "yAxis"
                ? chart[control].codes
                : Array.from(dctx[control].value_labels.keys())

        return values.map((value) => ({
            value,
            label: dctx.getDisplayLabel(control, value),
        }))
    }, [control, chart[control].codes])

    return variant === "tabs" ? (
        <ButtonControl
            data={items}
            value={chart[control].selected[0] ?? null}
            onChange={(val) => {
                form.setFieldValue(`chart.${control}.selected`, [val])
            }}
        />
    ) : (
        <SearchSelect
            items={items}
            value={chart[control].selected}
            onChange={(val) => {
                form.setFieldValue(`chart.${control}.selected`, val)
                control === "dimensions" && form.setFieldValue("chart.dimensions.customSort", null)
                onChange && onChange()
            }}
            placeholder={`Search ${dctx.getDisplayLabel(control)}`}
            singleSelect={chart[control].singleSelect}
            searchThreshold={5}
        />
    )
}

export const DFISelectOverlay: React.FC<{ control: SelectControlType; onClose: () => void }> = ({
    control,
    onClose,
}) => {
    const { form } = useChart()
    const chart = form.values.chart
    const dctx = useDataContext()
    return (
        <Overlay opacity={0.5} style={{ overflow: "hidden" }}>
            <Box
                p="md"
                h="calc(100% - 40px)"
                w={400}
                maw={"calc(100% - 40px)"}
                bg="white"
                my={20}
                mx="auto"
                style={{ border: "1px solid #ddd", overflow: "auto" }}
            >
                <Group position="apart" align="center" noWrap>
                    <Text fw={500}>Select {dctx.getDisplayLabel(control)}</Text>
                    <Button compact variant="light" onClick={() => onClose()}>
                        <IconX size="1em" />
                    </Button>
                </Group>
                <DFISelectControl
                    control={control}
                    variant="search"
                    onChange={() => chart[control].singleSelect && onClose()}
                />{" "}
            </Box>
        </Overlay>
    )
}

export const DFISelectControlsSidebar: React.FC = () => {
    const { form } = useChart()
    const { chart } = form.values
    const dctx = useDataContext()
    const theme = useMantineTheme()
    const [selectControl, setSelectControl] = React.useState<SelectControlType | null>(null)

    const visibleControls = SelectControlTypes.filter((key) => {
        const numValues = key === "yAxis" ? form.values.chart[key].codes.length : dctx[key].nunique
        return (
            numValues > 1 &&
            !form.values.chart[key].locked &&
            chart[key].selectDisplay === "sidebar"
        )
    })

    if (!visibleControls.length) {
        return null
    }

    if (selectControl && !visibleControls.includes(selectControl)) {
        setSelectControl(null)
    }

    const activeControl = selectControl ?? visibleControls[0]

    return (
        <Stack w={300} bg={theme.colors.gray[0]} p="md" style={{ overflow: "auto" }}>
            {visibleControls.length > 1 ? (
                <Tabs
                    value={activeControl}
                    onTabChange={(value: SelectControlType) => setSelectControl(value)}
                >
                    <Tabs.List>
                        {visibleControls.map((control) => (
                            <Tabs.Tab key={control} value={control}>
                                {dctx.getDisplayLabel(control)}
                            </Tabs.Tab>
                        ))}
                    </Tabs.List>
                </Tabs>
            ) : (
                <Text fw={500}>Select {dctx.getDisplayLabel(activeControl)}</Text>
            )}
            <DFISelectControl key={activeControl} control={activeControl} variant="search" />
        </Stack>
    )
}
