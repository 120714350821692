import _ from "lodash"
import { TableData } from "../types"

export interface DFIDataTable {
    id: string
    title?: string
    data: TableData
    index: string[]
    num_rows: number
    labels?: Record<string, string>
    value_labels?: Record<string, Record<string, string>>
}

export function getLabel(table: DFIDataTable, code: string) {
    return table.labels?.[code] ?? code
}

export function getValueLabel(table: DFIDataTable, code: string, value: any) {
    return table.value_labels?.[code]?.[value] ?? value
}

export function getColumnOptions(
    table: DFIDataTable,
    excludeList: string[],
    includeList?: string[],
) {
    const options = []
    for (const code of includeList ?? Object.keys(table.data)) {
        if (!excludeList.includes(code)) {
            options.push({ value: code, label: getLabel(table, code) })
        }
    }
    return options
}

export function joinColumns(table: DFIDataTable, codes: string[], sortedIndices?: number[]) {
    // filter codes against table columns
    codes = codes.filter((x) => table.data.hasOwnProperty(x))

    if (!codes.length) {
        return { values: [], value_labels: new Map(), nunique: 0 }
    }

    const values = Array<string>(table.num_rows)
    const value_labels: Map<string, string> = new Map()

    for (let i = 0; i < table.num_rows; i++) {
        const row_index = sortedIndices?.[i] ?? i
        const value_parts = codes.map((code) => table.data[code][row_index])
        const value = value_parts.join(" - ")
        values[row_index] = value
        if (!value_labels.hasOwnProperty(value)) {
            const joined_value_parts = value_parts
                .map((value, index) => {
                    return getValueLabel(table, codes[index], value)
                })
                .join(" - ")
            value_labels.set(value, joined_value_parts)
        }
    }

    return { values, value_labels, nunique: _.size(value_labels) }
}

export interface CsvOptions {
    delimiter?: string
    quote?: string
    header?: boolean
    format?: { [colname: string]: (value: any) => string }
    rows?: number[]
    cols?: string[]
}

export function toCsv(
    table: DFIDataTable,
    options: CsvOptions = {},
    labels?: { [key: string]: any },
): string {
    const { quote = '"', header = true, format = {} } = options
    const { data, num_rows } = table
    const delim = options.delimiter || ","
    const reFormat = new RegExp(`["${delim}\n\r]`)

    const formatValue = (value: any) =>
        value == null
            ? ""
            : reFormat.test((value += ""))
              ? '"' + value.replace(/"/g, '""') + '"'
              : value

    const columns = options?.cols ?? Object.keys(table.data)
    if (columns.length === 0) return ""

    const numRows = options.rows?.length ?? num_rows
    if (numRows === 0) return ""

    let csvString = header
        ? columns.map((col) => formatValue(labels?.[col] ?? getLabel(table, col))).join(delim) +
          "\n"
        : ""

    console.log("toCsv", { columns, numRows, table })
    for (let i = 0; i < numRows; i++) {
        const index = options.rows?.[i] ?? i

        csvString += columns
            .map((col) => {
                const value = getValueLabel(table, col, data[col][index])
                return formatValue(format[col]?.(value) ?? value)
            })
            .join(delim)
        i < numRows - 1 && (csvString += "\n")
    }

    return csvString
}
