import {
    ActionIcon,
    Box,
    Button,
    Center,
    Code,
    CopyButton,
    Flex,
    Group,
    List,
    Menu,
    Overlay,
    Popover,
    SimpleGrid,
    Text,
    Title,
    Tooltip,
    useMantineTheme,
} from "@mantine/core"
import { useElementSize } from "@mantine/hooks"
import { notifications } from "@mantine/notifications"
import {
    IconChartLine,
    IconCode,
    IconCopy,
    IconDownload,
    IconInfoCircle,
    IconLink,
    IconPhoto,
    IconPhotoDown,
    IconShare,
    IconTable,
} from "@tabler/icons-react"
import { ChartData, defaults as ChartJSDefaults } from "chart.js"
import html2canvas from "html2canvas"
import _ from "lodash"
import React, { useState } from "react"
import { DataTable, dt, utils } from "../../../dfi-utils"
import DFILogo from "../../../dfi-utils/src/assets/dfi-logo-with-text.svg"
import BarChart from "../charts/barchart"
import Dotplot from "../charts/dotplot"
import LineChart from "../charts/linechart"
import Map from "../charts/map"
import Marimekko from "../charts/marimekko"
import ScatterPlot from "../charts/scatterplot"
import Timeline from "../charts/timeline"
import { ChartType, getTilesPerRow } from "../config"
import { generateDatasets } from "../generate-datasets"
import { downloadAllSvgs } from "../plugins/export-svg"
import { highlightLegendData, resetChart } from "../plugins/hover"
import {
    CANVAS_HEIGHT,
    CANVAS_WIDTH,
    CHART_HEIGHT,
    CHART_SIDEBAR_BREAKPOINT,
    chartEmbedCode,
    dataTableFormatter,
    MIN_CHART_HEIGHT,
    shareChartUrl,
    tileIndicatorsCode,
} from "../utils"
import { useDataContext } from "./chart-data-context"
import { useChart } from "./context"
import {
    DFISelectControlsInline,
    DFISelectControlsSidebar,
    SelectControlTypes,
} from "./controls-select"
import DFILegend from "./legend"

ChartJSDefaults.font.family = "Roboto"
ChartJSDefaults.font.size = 11
ChartJSDefaults.color = "#555"

const ComponentMap = {
    [ChartType.BarChart]: BarChart,
    [ChartType.LineChart]: LineChart,
    [ChartType.ScatterPlot]: ScatterPlot,
    [ChartType.Marimekko]: Marimekko,
    [ChartType.Dotplot]: Dotplot,
    [ChartType.Map]: Map,
}

interface MenuItemProps {
    label: string
    icon: React.ReactNode
    onClick: () => void
}

interface DFIChartProps {
    adminControls?: boolean
    containerRef?: React.RefObject<HTMLDivElement>
    clone?: boolean
    setRenderClone?: (value: boolean) => void
}

type ActiveTabType = "chart" | "table"

const DFIChart: React.FC<DFIChartProps> = ({
    adminControls,
    containerRef,
    clone,
    setRenderClone,
}) => {
    const theme = useMantineTheme()
    const chartContext = useChart()
    const dctx = useDataContext()
    const { table, form } = chartContext

    const { time, type, yAxis, retainTablelabels } = form.values.chart
    const [activeTab, setActiveTab] = useState<ActiveTabType | null>("chart")

    const { width, ref } = useElementSize()
    const height = form.values.chart.height ?? CHART_HEIGHT
    const canHaveSidebar = width > CHART_SIDEBAR_BREAKPOINT

    const data = generateDatasets(chartContext, dctx)

    const style: React.CSSProperties = {
        border: `1px solid ${theme.colors.gray[3]}`,
        borderRadius: 5,
        overflow: "hidden",
    }

    return (
        <Flex h={height} w="100%" gap={0} style={style} ref={ref}>
            {canHaveSidebar && !clone && <DFISelectControlsSidebar />}
            <Flex
                className="dfi-chart-container"
                pos="relative"
                direction="column"
                style={{ flex: 1 }}
                p="md"
                gap={5}
                sx={{
                    "& p, & h3": { margin: 0 },
                    "& p": { color: theme.colors.gray[7] },
                    overflow: "hidden",
                }}
            >
                <DFIChartHeader />

                {activeTab === "chart" ? (
                    <>
                        {!clone && (
                            <DFISelectControlsInline includeSidebarControls={!canHaveSidebar} />
                        )}

                        <DFILegendContainer />

                        <DFIChartGridContainer data={data} />

                        {!clone && !!time.codes.length && time.display && (
                            <Timeline range={type === ChartType.LineChart} />
                        )}
                    </>
                ) : (
                    <Box style={{ overflow: "auto", flex: 1 }}>
                        <DataTable
                            table={table}
                            options={{
                                hideTitle: true,
                                hideOptions: true,
                                formatter: dataTableFormatter(chartContext),
                                labels: retainTablelabels
                                    ? {}
                                    : Object.fromEntries(
                                          Object.keys(yAxis.info).map((key) => [
                                              key,
                                              yAxis.info[key].label,
                                          ]),
                                      ),
                            }}
                        />
                    </Box>
                )}
                <DFIChartFooter
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    adminControls={adminControls}
                    containerRef={containerRef}
                    setRenderClone={setRenderClone}
                />
            </Flex>
        </Flex>
    )
}

interface DFILegendContainerProps {}

const DFILegendContainer: React.FC<DFILegendContainerProps> = () => {
    const { form } = useChart()
    const dctx = useDataContext()
    const { chart } = form.values
    const colname = chart.type === ChartType.ScatterPlot ? "colors" : "yAxis"

    const isSingleIndicator =
        chart.yAxis.codes.length === 1 || chart.tiles.codes.includes(tileIndicatorsCode)
    const hideLegend =
        (colname === "yAxis" && isSingleIndicator) ||
        chart.type === ChartType.LineChart ||
        chart.type === ChartType.Map

    const legendData = _.map(chart[colname]?.selected, (value) => ({
        label: dctx.getDisplayLabel(colname, value),
        color: dctx.legendData[value],
    }))

    return (
        chart.legend &&
        !hideLegend && (
            <Box m={5}>
                <DFILegend
                    data={legendData}
                    onHover={(index) => highlightLegendData(index, chart.type)}
                    onMouseOut={() => resetChart(chart.type)}
                />
            </Box>
        )
    )
}

interface DFIChartGridContainerProps {
    data: ChartData[]
}
const DFIChartGridContainer: React.FC<DFIChartGridContainerProps> = ({ data }) => {
    const { form } = useChart()
    let { type, numcols } = form.values.chart
    const { ref, width, height } = useElementSize()

    numcols = numcols ?? getTilesPerRow(type)

    const ChartComponent = ComponentMap[type]
    const numChartCols = width >= 400 && data.length > 1 ? numcols : 1
    const numChartRows = Math.ceil(data.length / numChartCols)
    const chartHeight = height / numChartRows

    const maxVisibleRows = Math.floor(height / MIN_CHART_HEIGHT)
    const minChartHeight = height / maxVisibleRows

    return (
        <Box ref={ref} key={data.length} style={{ overflow: "auto", flex: 1 }}>
            <SimpleGrid
                key={width + height + data.length}
                cols={numChartCols}
                spacing="xs"
                verticalSpacing={0}
                h="100%"
                style={{ gridAutoRows: "1fr" }}
                className="dfi-chart-grid"
            >
                {data.map((dataset: ChartData, index: number) => {
                    return (
                        <Box
                            key={index}
                            mih={minChartHeight}
                            h={chartHeight}
                            pos="relative"
                            onPointerLeave={() => resetChart(type)}
                        >
                            <ChartComponent data={dataset} index={index} />
                        </Box>
                    )
                })}
            </SimpleGrid>
        </Box>
    )
}
const DFIChartHeader: React.FC = () => {
    const { form } = useChart()
    const { text } = form.values.chart
    const theme = useMantineTheme()
    return (
        <Box>
            <Text component="h3" fz={14} fw={500}>
                {text.title}
            </Text>
            <Text component="p" fz={12} c={theme.colors.gray[7]}>
                {text.subtitle}
            </Text>
        </Box>
    )
}

const ChartInfo: React.FC = () => {
    const { form } = useChart()
    const chart = form.values.chart
    const dctx = useDataContext()
    const { time, yAxis } = form.values.chart

    const hasSelectControls = SelectControlTypes.some((control) => {
        const numValues = control === "yAxis" ? yAxis.codes.length : dctx[control].nunique
        const selectDisplay = chart[control].selectDisplay ?? "overlay"
        return numValues > 1 && !chart[control].locked && selectDisplay === "overlay"
    })

    return (
        <Box>
            <Title size="h6" mb={10}>
                How to interact with this chart
            </Title>
            <List spacing="xs">
                {hasSelectControls && (
                    <List.Item>
                        Click on the blue{" "}
                        <Text style={{ display: "inline" }} c="blue">
                            Select ...
                        </Text>{" "}
                        above the chart to choose other metrics from this dataset
                    </List.Item>
                )}
                <List.Item>
                    Use the share icon to download this chart, embed it or download the data
                </List.Item>
                <List.Item>
                    Use the icon next to the share button to toggle between chart and table views
                </List.Item>
                {!!time.codes.length && time.display && (
                    <List.Item>
                        Use the Play button below the chart to view changes over time
                    </List.Item>
                )}
            </List>
        </Box>
    )
}

interface FeatureImageSettingProps {
    datalabels: boolean
    xGrid?: boolean
    xLabels?: boolean
    yGrid?: boolean
    yLabels?: boolean
}

interface DFIChartFooterProps {
    activeTab: ActiveTabType | null
    setActiveTab: (tab: ActiveTabType) => void
    containerRef?: React.RefObject<HTMLDivElement>
    adminControls?: boolean
    setRenderClone?: (value: boolean) => void
}

const DFIChartFooter: React.FC<DFIChartFooterProps> = ({
    activeTab,
    setActiveTab,
    containerRef,
    adminControls,
    setRenderClone,
}) => {
    const { form, table } = useChart()
    const dctx = useDataContext()
    const { text, yAxis, retainTablelabels } = form.values.chart
    const [open, setOpen] = useState<boolean>(false)
    const [modal, setModal] = useState<string | null>(null)
    const downloadFileName = `${form.values.chart.text.title ?? "Data For India"}`
    const labels = retainTablelabels
        ? {}
        : Object.fromEntries(Object.keys(yAxis.info).map((key) => [key, yAxis.info[key].label]))

    function download(filtered?: boolean) {
        const data = dt.toCsv(table, filtered ? { rows: dctx.filteredIndices } : {}, labels)
        const filename = `${downloadFileName}.csv`
        utils.downloadFile(data, filename, "csv")
    }

    function downloadImage(social?: boolean) {
        if (!containerRef || !setRenderClone) {
            return
        }
        setRenderClone(true)
        setTimeout(() => {
            if (social) {
                containerRef.current!.style.backgroundColor = "#FDF5E6"
            }
            html2canvas(containerRef.current!).then((canvas) => {
                const data = canvas.toDataURL("image/jpeg")
                const filename = `${downloadFileName}.jpg`
                utils.downloadFile(data, filename, "image")
                setRenderClone(false)
            })
        }, 500)
    }

    function createFeatureImage(prevSettings: FeatureImageSettingProps) {
        html2canvas(document.querySelector(".dfi-chart-grid") as HTMLElement, {
            onclone: (doc) => {
                const chartGrid = doc.querySelector(".dfi-chart-grid") as HTMLElement
                chartGrid.style.border = "10px solid white"
            },
        }).then((canvas) => {
            const resizedCanvas = document.createElement("canvas")
            const resizedContext = resizedCanvas.getContext("2d")!

            resizedCanvas.width = CANVAS_WIDTH
            resizedCanvas.height = CANVAS_HEIGHT

            resizedContext.drawImage(canvas, 0, 0, CANVAS_WIDTH, CANVAS_HEIGHT)

            const data = resizedCanvas.toDataURL("image/jpeg")
            const filename = `${downloadFileName}-feature.jpg`
            utils.downloadFile(data, filename, "image")

            // Reset chart settings
            form.setFieldValue("chart.datalabels", prevSettings.datalabels)
            form.setFieldValue("chart.yAxis.hideGridlines", prevSettings.yGrid)
            form.setFieldValue("chart.yAxis.hideAxislabels", prevSettings.yLabels)
            form.setFieldValue("chart.categorylabels", true)

            if (form.values.chart.xAxis) {
                form.setFieldValue("chart.xAxis.hideGridlines", prevSettings.xGrid)
                form.setFieldValue("chart.xAxis.hideAxislabels", prevSettings.xLabels)
            }
            notifications.show({
                message: "Feature Image Created",
                autoClose: 5000,
            })
        })
    }

    function handleCreateFeatureImage() {
        const prevSettings: FeatureImageSettingProps = {
            datalabels: form.values.chart.datalabels,
            xGrid: form.values.chart.xAxis?.hideGridlines,
            xLabels: form.values.chart.xAxis?.hideAxislabels,
            yGrid: form.values.chart.yAxis.hideGridlines,
            yLabels: form.values.chart.yAxis.hideAxislabels,
        }

        // Set chart settings for feature image
        form.setFieldValue("chart.datalabels", false)
        form.setFieldValue("chart.yAxis.hideGridlines", true)
        form.setFieldValue("chart.yAxis.hideAxislabels", true)
        form.setFieldValue("chart.categorylabels", false)

        if (form.values.chart.xAxis) {
            form.setFieldValue("chart.xAxis.hideGridlines", true)
            form.setFieldValue("chart.xAxis.hideAxislabels", true)
        }
        setTimeout(() => {
            createFeatureImage(prevSettings)
        }, 500)
    }

    const createMenuItem = (
        label: string,
        icon: React.ReactNode,
        onClick: () => void,
    ): MenuItemProps => ({
        label,
        icon,
        onClick,
    })

    const imageOptions: MenuItemProps[] =
        activeTab === "chart"
            ? [
                  createMenuItem("Download Image", <IconPhotoDown size="1em" />, () =>
                      downloadImage(),
                  ),
                  ...(form.values.code && adminControls
                      ? [
                            createMenuItem(
                                "Create Feature Image",
                                <IconPhoto size="1em" />,
                                handleCreateFeatureImage,
                            ),
                            createMenuItem("Download Social Image", <IconPhoto size="1em" />, () =>
                                downloadImage(true),
                            ),
                            createMenuItem(
                                "Download SVGs",
                                <IconPhoto size="1em" />,
                                downloadAllSvgs,
                            ),
                        ]
                      : []),
              ]
            : []

    const dataOptions: MenuItemProps[] = [
        createMenuItem("Download Chart Data", <IconDownload size="1em" />, () => download(true)),
        createMenuItem("Download Full Data", <IconDownload size="1em" />, download),
    ]

    const codeOptions: MenuItemProps[] = form.values.code
        ? [
              createMenuItem("Embed", <IconCode size="1em" />, () => setModal("embed")),
              createMenuItem("Copy Link", <IconLink size="1em" />, () => {
                  const url = shareChartUrl(form.values.code!)
                  navigator.clipboard.writeText(url)
              }),
          ]
        : []

    const options: MenuItemProps[] = [...imageOptions, ...dataOptions, ...codeOptions]

    const embedCode = chartEmbedCode(form.values.code!, form.values.chart.height ?? CHART_HEIGHT)

    return (
        <>
            <Box fz={10}>
                <Text fz={10} component="p">
                    {text.notes}
                </Text>
                {text.sources?.length ? (
                    <Text component="p">
                        <i>{`Source: ${text.sources}`}</i>
                    </Text>
                ) : null}
            </Box>
            <Flex
                justify="space-between"
                align="center"
                gap={12}
                sx={(theme) => ({
                    ".tabler-icon": { color: theme.colors.gray[7] },
                })}
            >
                <img src={DFILogo} alt="Data For India logo" width={122} height={22} />
                <Group noWrap align="baseline" spacing="xs" data-html2canvas-ignore="true">
                    <Popover width={250} position="bottom" withArrow shadow="md">
                        <Popover.Target>
                            <Tooltip label="Info">
                                <ActionIcon
                                    className="plausible-event-name=Click+Info"
                                    variant="light"
                                    aria-label="info"
                                >
                                    <IconInfoCircle size="1.2em" />
                                </ActionIcon>
                            </Tooltip>
                        </Popover.Target>
                        <Popover.Dropdown>
                            <ChartInfo />
                        </Popover.Dropdown>
                    </Popover>
                    <Tooltip label={activeTab === "chart" ? "Table" : "Chart"}>
                        <ActionIcon
                            className="plausible-event-name=View+as+Table"
                            variant="light"
                            onClick={() => setActiveTab(activeTab === "chart" ? "table" : "chart")}
                            aria-label={activeTab === "chart" ? "table view" : "chart view"}
                        >
                            {activeTab === "chart" ? (
                                <IconTable size="1.2em" />
                            ) : (
                                <IconChartLine size="1.2em" />
                            )}
                        </ActionIcon>
                    </Tooltip>
                    <Menu
                        opened={open}
                        onChange={setOpen}
                        position="top-end"
                        data-html2canvas-ignore="true"
                    >
                        <Menu.Target>
                            <Tooltip label="Share">
                                <ActionIcon
                                    className="plausible-event-name=Click+Share"
                                    variant="light"
                                    aria-label="share"
                                >
                                    <IconShare size="1.2em" />
                                </ActionIcon>
                            </Tooltip>
                        </Menu.Target>
                        <Menu.Dropdown data-html2canvas-ignore="true">
                            {options.map((option, index) => (
                                <Menu.Item
                                    className={`plausible-event-name=${option.label
                                        .split(" ")
                                        .join("+")}`}
                                    key={index}
                                    icon={option.icon}
                                    onClick={option.onClick}
                                >
                                    {option.label}
                                </Menu.Item>
                            ))}
                        </Menu.Dropdown>
                    </Menu>
                </Group>
            </Flex>

            {modal === "embed" && (
                <Overlay>
                    <Center m="md">
                        <Group position="apart" align="start" bg="white" p="md" noWrap>
                            <Code fz="sm" p="sm">
                                {embedCode}
                            </Code>
                            <CopyButton value={embedCode}>
                                {({ copied, copy }) => (
                                    <Button
                                        className="plausible-event-name=Copy+Embed+Code"
                                        compact
                                        variant="light"
                                        size="sm"
                                        onClick={() => {
                                            copy()
                                            setTimeout(() => setModal(null), 400)
                                        }}
                                        leftIcon={<IconCopy size="1em" />}
                                        color={copied ? "teal" : "gray"}
                                    >
                                        Copy & Close
                                    </Button>
                                )}
                            </CopyButton>
                        </Group>
                    </Center>
                </Overlay>
            )}
        </>
    )
}

export default DFIChart
